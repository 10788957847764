// src/utils/optionHandlers.js
export const createOptionHandlers = (
    setCustomOptions, 
    setSelectedPreset, 
    presets
  ) => {
    // 处理单个选项变更
    const handleOptionChange = (key, value) => {
      setCustomOptions(prev => {
        const newOptions = {
          ...prev,
          [key]: value
        };
  
        // 检查是否匹配任何预设
        const matchedPreset = Object.entries(presets).find(([, preset]) => 
          Object.entries(preset.config).every(([k, v]) => newOptions[k] === v)
        );
  
        // 如果找到匹配的预设，更新选中的预设
        if (matchedPreset) {
          setSelectedPreset(matchedPreset[0]);
        } else {
          setSelectedPreset('custom');
        }
  
        // 处理相关选项的联动
        switch (key) {
          case 'stringArray':
            // 如果禁用了 stringArray，相关选项也要禁用
            if (!value) {
              return {
                ...newOptions,
                stringArrayEncoding: [],
                stringArrayCallsTransform: false,
                stringArrayRotate: false,
                stringArrayShuffle: false
              };
            }
            break;
  
          case 'controlFlowFlattening':
            // 如果启用了控制流扁平化，设置默认阈值
            if (value && !newOptions.controlFlowFlatteningThreshold) {
              return {
                ...newOptions,
                controlFlowFlatteningThreshold: 0.75
              };
            }
            break;
  
          case 'deadCodeInjection':
            // 如果启用了死代码注入，设置默认阈值
            if (value && !newOptions.deadCodeInjectionThreshold) {
              return {
                ...newOptions,
                deadCodeInjectionThreshold: 0.4
              };
            }
            break;
  
          case 'debugProtection':
            // 如果启用了调试保护，设置默认间隔
            if (value && !newOptions.debugProtectionInterval) {
              return {
                ...newOptions,
                debugProtectionInterval: 4000
              };
            }
            break;
  
          case 'stringArrayEncoding':
            // 如果设置了编码，确保启用相关选项
            if (value && value.length > 0) {
              return {
                ...newOptions,
                stringArray: true,
                stringArrayRotate: true,
                stringArrayShuffle: true
              };
            }
            break;
        }
  
        return newOptions;
      });
    };
  
    // 处理相关数值选项变更
    const handleNumberOptionChange = (key, value) => {
      const numValue = parseFloat(value);
      if (!isNaN(numValue)) {
        // 根据不同选项设置合适的范围
        let finalValue = numValue;
        switch (key) {
          case 'controlFlowFlatteningThreshold':
          case 'deadCodeInjectionThreshold':
          case 'stringArrayThreshold':
            finalValue = Math.max(0, Math.min(1, numValue));
            break;
            
          case 'debugProtectionInterval':
            finalValue = Math.max(0, Math.round(numValue));
            break;
  
          case 'splitStringsChunkLength':
          case 'stringArrayWrappersCount':
          case 'stringArrayWrappersParametersMaxCount':
            finalValue = Math.max(1, Math.round(numValue));
            break;
        }
        handleOptionChange(key, finalValue);
      }
    };
  
    // 处理预设变更
    const handlePresetChange = (presetName) => {
      if (presets[presetName]) {
        setSelectedPreset(presetName);
        setCustomOptions(presets[presetName].config);
      }
    };
  
    // 重置为默认设置
    const resetOptions = () => {
      handlePresetChange('default');
    };
  
    return {
      handleOptionChange,
      handleNumberOptionChange,
      handlePresetChange,
      resetOptions
    };
  };