// src/services/obfuscatorService.js
class ObfuscatorService {
    constructor() {
        this.baseUrl = 'https://basechain-service.akakaka.top/api/v1';
    }

    /**
     * 移除代码中的注释
     */
    removeComments(code) {
        try {
            // 移除单行注释（保留URL中的//）
            code = code.replace(/(?<!:)\/\/.*/g, '');
            
            // 移除多行注释
            code = code.replace(/\/\*[\s\S]*?\*\//g, '');
            
            // 移除空行并规范化空白
            code = code.split('\n')
                .filter(line => line.trim() !== '')
                .map(line => line.trimRight())  // 保留缩进，移除行尾空白
                .join('\n');
                
            return code;
        } catch (error) {
            console.warn('注释移除过程出错:', error);
            return code; // 如果出错则返回原始代码
        }
    }

    /**
     * 转换选项格式
     */
    convertOptions(options) {
        // 如果已经是预设格式，直接返回
        if (options.preset) {
            return options;
        }

        // 转换旧格式到新格式
        return {
            controlFlowFlattening: options.controlFlow || false,
            deadCodeInjection: options.deadCodeInjection || false,
            stringArrayEncoding: options.stringArrayEncrypt ? ['base64'] : [],
            unicodeEscapeSequence: options.unicodeEncrypt || false,
            numbersToExpressions: options.constantEncrypt || false,
            renameProperties: options.memberEncrypt || false,
            renameGlobals: options.globalFunctionEncrypt || false,
            
            // 默认选项
            compact: true,
            selfDefending: false,
            stringArray: true,
            stringArrayRotate: true,
            stringArrayShuffle: true,
            stringArrayThreshold: 0.75,
            identifierNamesGenerator: 'hexadecimal'
        };
    }

    /**
     * 混淆代码
     */
    async obfuscateCode(code, options = {}) {
        try {
            const token = this.checkAuth();

            // 清理代码中的注释
            const cleanedCode = this.removeComments(code);

            // 验证选项
            this.validateOptions(options);

            // 准备请求数据
            const requestData = {
                code: cleanedCode,
                options: this.convertOptions(options)
            };

            // 调试日志
            if (process.env.NODE_ENV === 'development') {
                console.log('Obfuscation request:', {
                    codeLength: cleanedCode.length,
                    options: requestData.options
                });
            }

            // 发送请求
            const response = await fetch(`${this.baseUrl}/obfuscate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include',
                body: JSON.stringify(requestData)
            });

            if (!response.ok) {
                if (response.status === 401) {
                    this.handleAuthError();
                } else if (response.status === 429) {
                    throw new Error('请求过于频繁，请稍后再试');
                }
                
                const errorData = await response.json();
                throw new Error(errorData.error || '服务器错误');
            }

            const data = await response.json();
            if (!data.success) {
                throw new Error(data.error || '混淆失败');
            }

            return {
                success: true,
                code: data.code,
                stats: data.stats || {
                    originalSize: code.length,
                    obfuscatedSize: data.code.length,
                    duration: data.duration || 'N/A',
                    compressionRatio: data.stats?.compressionRatio
                },
                requestId: data.requestId
            };

        } catch (error) {
            console.error('混淆服务错误:', error);
            throw error;
        }
    }

    /**
     * 获取混淆配置信息
     */
    async getConfig() {
        try {
            const token = this.checkAuth();

            const response = await fetch(`${this.baseUrl}/config`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include'
            });

            if (!response.ok) {
                if (response.status === 401) {
                    this.handleAuthError();
                }
                const errorData = await response.json();
                throw new Error(errorData.error || '获取配置失败');
            }

            return await response.json();
        } catch (error) {
            console.error('获取配置失败:', error);
            throw error;
        }
    }

    /**
     * 获取使用统计
     */
    async getStats() {
        try {
            const token = this.checkAuth();

            const response = await fetch(`${this.baseUrl}/stats`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include'
            });

            if (!response.ok) {
                if (response.status === 401) {
                    this.handleAuthError();
                }
                const errorData = await response.json();
                throw new Error(errorData.error || '获取统计信息失败');
            }

            return await response.json();
        } catch (error) {
            console.error('获取统计信息失败:', error);
            throw error;
        }
    }

    /**
     * 调试用：检查代码清理效果
     */
    debugCodeCleaning(code) {
        const cleanedCode = this.removeComments(code);
        return {
            original: code,
            cleaned: cleanedCode,
            originalLength: code.length,
            cleanedLength: cleanedCode.length,
            differencePct: ((code.length - cleanedCode.length) / code.length * 100).toFixed(2) + '%'
        };
    }

    /**
     * 验证选项合法性
     */
    validateOptions(options) {
        // 预设验证
        if (options.preset && !['high', 'medium', 'low', 'default'].includes(options.preset)) {
            throw new Error('无效的预设选项');
        }

        // 阈值验证
        const thresholdOptions = [
            'controlFlowFlatteningThreshold',
            'deadCodeInjectionThreshold',
            'stringArrayThreshold'
        ];

        thresholdOptions.forEach(option => {
            if (options[option] !== undefined) {
                const value = parseFloat(options[option]);
                if (isNaN(value) || value < 0 || value > 1) {
                    throw new Error(`选项 ${option} 必须在 0 到 1 之间`);
                }
            }
        });

        return true;
    }

    /**
     * 检查认证状态
     */
    checkAuth() {
        const token = localStorage.getItem('token');
        if (!token) {
            throw new Error('未登录');
        }
        return token;
    }

    /**
     * 处理认证错误
     */
    handleAuthError() {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        window.location.href = '/login';
        throw new Error('登录已过期，请重新登录');
    }
}

export default new ObfuscatorService();
