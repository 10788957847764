// file: src/services/ttsService.js
class TTSService {
    constructor() {
        this.baseUrl = 'https://basechain-service.akakaka.top';
        this.apiPath = '/api/tts';
    }

    buildUrl(path) {
        const fullPath = path.startsWith('/') ? path.substring(1) : path;
        return `${this.baseUrl}/${fullPath}`;
    }

    async generateSpeech(text, voice, options = {}) {
        const token = this.checkAuth();

        try {
            const response = await fetch(`${this.baseUrl}${this.apiPath}/generate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include',
                body: JSON.stringify({
                    text,
                    voice,
                    rate: options.rate || '+0%',
                    volume: options.volume || '+0%',
                    format: options.format || 'mp3'
                })
            });

            if (response.status === 401) {
                this.handleAuthError(new Error('登录已过期，请重新登录'));
            }

            const data = await response.json();
            if (!data.success) {
                throw new Error(data.error || '生成失败');
            }

            // 直接返回完整的音频URL
            return `${this.baseUrl}${data.file_url}`;
        } catch (error) {
            console.error('TTS服务错误:', error);
            throw error;
        }
    }

    async getVoices() {
        const token = this.checkAuth();

        try {
            const response = await fetch(`${this.baseUrl}${this.apiPath}/voices`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include'
            });

            if (response.status === 401) {
                this.handleAuthError(new Error('登录已过期，请重新登录'));
            }

            const data = await response.json();
            if (!data.success) {
                throw new Error(data.error || '获取语音列表失败');
            }

            return data.voices || [];
        } catch (error) {
            console.error('获取语音列表失败:', error);
            return [];
        }
    }

    async downloadAudio(fileUrl) {
        const token = this.checkAuth();

        try {
            const response = await fetch(fileUrl, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include'
            });

            if (response.status === 401) {
                this.handleAuthError(new Error('登录已过期，请重新登录'));
            }

            if (!response.ok) {
                throw new Error('下载失败');
            }

            return response.blob();
        } catch (error) {
            console.error('下载音频失败:', error);
            throw error;
        }
    }

    checkAuth() {
        const token = localStorage.getItem('token');
        if (!token) {
            throw new Error('未登录');
        }
        return token;
    }

    handleAuthError(error) {
        if (error.message.includes('登录已过期') || error.message.includes('未登录')) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            window.location.href = '/login';
        }
        throw error;
    }
}

export default new TTSService();
