// file: src/components/Layout.js
import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { 
    Code, 
    Mic2, 
    ChevronLeft, 
    LogOut, 
    User,
    Menu
} from 'lucide-react';

const Layout = ({ children }) => {
    const { user, logout } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(true);
    const [showUserMenu, setShowUserMenu] = useState(false);

    const menuItems = [
        { path: '/obfuscator', icon: Code, label: 'JS混淆器' },
        { path: '/tts', icon: Mic2, label: '语音合成' },
        // 这里可以继续添加新的功能项
    ];

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    return (
        <div className="min-h-screen bg-gray-100">
            {/* 侧边栏 */}
            <div className={`fixed top-0 left-0 h-full bg-gray-900 text-white transition-all duration-300 ${isOpen ? 'w-64' : 'w-20'}`}>
                <div className="flex items-center justify-between p-4">
                    <h1 className={`font-bold ${isOpen ? 'text-xl' : 'hidden'}`}>工具箱</h1>
                    <button 
                        onClick={() => setIsOpen(!isOpen)}
                        className="p-2 rounded-lg hover:bg-gray-800"
                    >
                        <ChevronLeft className={`transform transition-transform ${!isOpen && 'rotate-180'}`} />
                    </button>
                </div>

                <nav className="mt-8">
                    {menuItems.map((item) => (
                        <Link
                            key={item.path}
                            to={item.path}
                            className={`flex items-center px-4 py-3 hover:bg-gray-800 ${
                                location.pathname === item.path ? 'bg-gray-800' : ''
                            }`}
                        >
                            <item.icon size={20} />
                            {isOpen && <span className="ml-4">{item.label}</span>}
                        </Link>
                    ))}
                </nav>
            </div>

            {/* 主内容区 */}
            <div className={`transition-all duration-300 ${isOpen ? 'ml-64' : 'ml-20'}`}>
                {/* 顶部栏 */}
                <header className="bg-white shadow-sm">
                    <div className="flex items-center justify-between px-6 py-4">
                        <div className="flex items-center">
                            <Menu 
                                className="md:hidden mr-4 cursor-pointer"
                                onClick={() => setIsOpen(!isOpen)}
                            />
                            <h1 className="text-xl font-semibold text-gray-800">
                                {menuItems.find(item => item.path === location.pathname)?.label}
                            </h1>
                        </div>

                        {/* 用户信息 */}
                        <div className="relative">
                            <button
                                onClick={() => setShowUserMenu(!showUserMenu)}
                                className="flex items-center space-x-3 focus:outline-none"
                            >
                                <div className="w-8 h-8 bg-gray-300 rounded-full flex items-center justify-center">
                                    <User size={20} />
                                </div>
                                <span className="text-gray-700">{user?.email}</span>
                            </button>

                            {showUserMenu && (
                                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1">
                                    <button
                                        onClick={handleLogout}
                                        className="flex items-center w-full px-4 py-2 text-gray-700 hover:bg-gray-100"
                                    >
                                        <LogOut size={16} className="mr-2" />
                                        退出登录
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </header>

                {/* 页面内容 */}
                <main className="p-6">
                    {children}
                </main>
            </div>
        </div>
    );
};

export default Layout;