// file: src/App.js
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Layout from './components/Layout';
import LoginPage from './components/LoginPage';
import ObfuscatorUI from './components/ObfuscatorUI';
import TTSGenerator from './components/TTSGenerator';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
    return (
        <AuthProvider>
            <Router>
                <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    <Route
                        path="/obfuscator"
                        element={
                            <ProtectedRoute>
                                <Layout>
                                    <ObfuscatorUI />
                                </Layout>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/tts"
                        element={
                            <ProtectedRoute>
                                <Layout>
                                    <TTSGenerator />
                                </Layout>
                            </ProtectedRoute>
                        }
                    />
                    <Route path="/" element={<Navigate to="/obfuscator" replace />} />
                </Routes>
            </Router>
        </AuthProvider>
    );
}

export default App;