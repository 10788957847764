// file: src/components/ObfuscatorUI.js
import { useState } from 'react';
import { Download, Copy, Code, Settings, RefreshCw } from 'lucide-react';
import obfuscatorService from '../services/obfuscatorService';
import { obfuscatorPresets } from '../config/presets';
import { createOptionHandlers } from '../utils/optionHandlers';

const ObfuscationOptions = {
  // 基本设置
  general: {
    title: "基本设置",
    options: [
      { key: 'target', type: 'select', label: '目标环境',
        options: ['Browser', 'Node'] },
      { key: 'seed', type: 'number', label: '随机种子', defaultValue: 0 },
      { key: 'sourceMap', type: 'checkbox', label: '启用源码映射' },
      { key: 'sourceMapMode', type: 'select', label: '映射模式',
        options: ['Separate', 'Inline'], dependsOn: 'sourceMap' }
    ]
  },
  // 字符串处理选项
  strings: {
    title: "字符串转换",
    options: [
      { key: 'stringArray', type: 'checkbox', label: '字符串数组转换' },
      { key: 'stringArrayRotate', type: 'checkbox', label: '字符串数组轮换',
        dependsOn: 'stringArray' },
      { key: 'stringArrayShuffle', type: 'checkbox', label: '字符串数组打乱',
        dependsOn: 'stringArray' },
      { key: 'stringArrayThreshold', type: 'number', label: '转换阈值',
        min: 0, max: 1, step: 0.1, defaultValue: 0.75, dependsOn: 'stringArray' },
      { key: 'stringArrayIndexShift', type: 'checkbox', label: '索引偏移',
        dependsOn: 'stringArray' },
      { key: 'stringArrayEncoding', type: 'multiSelect', label: '编码方式',
        options: ['None', 'Base64', 'RC4'], dependsOn: 'stringArray' },
      { key: 'stringArrayWrappersCount', type: 'number', label: '包装器数量',
        min: 1, defaultValue: 1, dependsOn: 'stringArray' },
      { key: 'splitStrings', type: 'checkbox', label: '字符串分割' },
      { key: 'unicodeEscapeSequence', type: 'checkbox', label: 'Unicode转义' }
    ]
  },
  // 标识符处理
  identifiers: {
    title: "标识符转换",
    options: [
      { key: 'identifierNamesGenerator', type: 'select', label: '命名生成器',
        options: ['hexadecimal', 'mangled'] },
      { key: 'renameGlobals', type: 'checkbox', label: '全局变量重命名' },
      { key: 'renameProperties', type: 'checkbox', label: '属性重命名' },
      { key: 'renamePropertiesMode', type: 'select', label: '属性重命名模式',
        options: ['safe', 'unsafe'], dependsOn: 'renameProperties' }
    ]
  },
  // 控制流和代码结构
  controlFlow: {
    title: "控制流转换",
    options: [
      { key: 'controlFlowFlattening', type: 'checkbox', label: '控制流扁平化' },
      { key: 'controlFlowFlatteningThreshold', type: 'number', label: '扁平化阈值',
        min: 0, max: 1, step: 0.1, defaultValue: 0.75, dependsOn: 'controlFlowFlattening' },
      { key: 'deadCodeInjection', type: 'checkbox', label: '死代码注入' },
      { key: 'deadCodeInjectionThreshold', type: 'number', label: '注入阈值',
        min: 0, max: 1, step: 0.1, defaultValue: 0.4, dependsOn: 'deadCodeInjection' },
      { key: 'numbersToExpressions', type: 'checkbox', label: '数值表达式转换' },
      { key: 'simplify', type: 'checkbox', label: '简化代码' },
      { key: 'transformObjectKeys', type: 'checkbox', label: '转换对象键名' }
    ]
  },
  // 调试和安全
  security: {
    title: "安全与调试",
    options: [
      { key: 'compact', type: 'checkbox', label: '压缩代码' },
      { key: 'selfDefending', type: 'checkbox', label: '自我保护' },
      { key: 'debugProtection', type: 'checkbox', label: '调试保护' },
      { key: 'debugProtectionInterval', type: 'number', label: '保护检查间隔',
        min: 0, defaultValue: 0, dependsOn: 'debugProtection' },
      { key: 'disableConsoleOutput', type: 'checkbox', label: '禁用控制台输出' },
      { key: 'domainLock', type: 'array', label: '域名锁定' },
      { key: 'domainLockRedirectUrl', type: 'text', label: '域名锁定重定向',
        defaultValue: 'about:blank', dependsOn: 'domainLock' }
    ]
  }
};

// 选项控件组件
const OptionControl = ({ option, value, onChange, disabled }) => {
  switch (option.type) {
    case 'checkbox':
      return (
        <label className="flex items-center space-x-2 hover:bg-gray-50 p-1 rounded">
          <input
            type="checkbox"
            checked={value ?? option.defaultValue}
            onChange={(e) => onChange(e.target.checked)}
            disabled={disabled}
            className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
          />
          <span className="text-sm text-gray-700">{option.label}</span>
        </label>
      );

    case 'number':
      return (
        <label className="block">
          <span className="text-sm text-gray-700">{option.label}</span>
          <input
            type="number"
            value={value ?? option.defaultValue}
            onChange={(e) => onChange(parseFloat(e.target.value))}
            min={option.min}
            max={option.max}
            step={option.step}
            disabled={disabled}
            className="mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </label>
      );

    case 'select':
      return (
        <label className="block">
          <span className="text-sm text-gray-700">{option.label}</span>
          <select
            value={value ?? option.defaultValue}
            onChange={(e) => onChange(e.target.value)}
            disabled={disabled}
            className="mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            {option.options.map(opt => (
              <option key={opt} value={opt}>{opt}</option>
            ))}
          </select>
        </label>
      );

    case 'multiSelect':
      return (
        <label className="block">
          <span className="text-sm text-gray-700">{option.label}</span>
          <select
            multiple
            value={value ?? option.defaultValue ?? []}
            onChange={(e) => onChange(Array.from(e.target.selectedOptions, option => option.value))}
            disabled={disabled}
            className="mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            {option.options.map(opt => (
              <option key={opt} value={opt}>{opt}</option>
            ))}
          </select>
        </label>
      );

    case 'array':
      return (
        <label className="block">
          <span className="text-sm text-gray-700">{option.label}</span>
          <div className="mt-1 flex space-x-2">
            <input
              type="text"
              placeholder={option.placeholder}
              value={(value ?? []).join('\n')}
              onChange={(e) => onChange(e.target.value.split('\n').filter(Boolean))}
              disabled={disabled}
              className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
        </label>
      );

    default:
      return null;
  }
};

// 选项组组件
const OptionGroup = ({ title, options, values, onChange, expandedGroup, setExpandedGroup }) => {
  const isExpanded = expandedGroup === title;

  return (
    <div className="border rounded-lg p-4">
      <button
        onClick={() => setExpandedGroup(isExpanded ? null : title)}
        className="w-full flex justify-between items-center"
      >
        <h3 className="font-medium text-lg">{title}</h3>
        <Settings
          size={18}
          className={`transform transition-transform ${isExpanded ? 'rotate-180' : ''}`}
        />
      </button>
      
      {isExpanded && (
        <div className="mt-4 space-y-4">
          {options.map(option => {
            const isDisabled = option.dependsOn && !values[option.dependsOn];
            return (
              <OptionControl
                key={option.key}
                option={option}
                value={values[option.key]}
                onChange={(value) => onChange(option.key, value)}
                disabled={isDisabled}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

const ObfuscatorUI = () => {
  const [inputCode, setInputCode] = useState('');
  const [outputCode, setOutputCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPreset, setSelectedPreset] = useState('default');
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [customOptions, setCustomOptions] = useState(obfuscatorPresets.default.config);
  // 添加展开分组状态
  const [expandedGroup, setExpandedGroup] = useState(null);

  const {
    handleOptionChange,
    handlePresetChange,
    resetOptions
  } = createOptionHandlers(setCustomOptions, setSelectedPreset, obfuscatorPresets);

  const handleObfuscate = async () => {
    if (!inputCode.trim()) {
      alert('请输入需要混淆的代码！');
      return;
    }

    setIsLoading(true);
    try {
      const options = {
        preset: selectedPreset,
        ...(showAdvanced ? customOptions : {})
      };
      
      const result = await obfuscatorService.obfuscateCode(inputCode, options);
      
      if (result.code) {
        setOutputCode(result.code);
        
        // 如果有统计信息，显示在界面上
        if (result.stats) {
          const { originalSize, obfuscatedSize, duration } = result.stats;
          const reduction = ((1 - obfuscatedSize / originalSize) * 100).toFixed(1);
          const message = `混淆成功！\n代码大小: ${originalSize} → ${obfuscatedSize} 字节 (减少 ${reduction}%)\n处理时间: ${duration}`;
          console.log(message);
          // 可以添加一个 Toast 或其他提示来显示这些信息
        }
      } else {
        throw new Error('混淆结果无效');
      }
    } catch (error) {
      console.error('混淆失败:', error);
      // 使用更友好的错误提示
      const errorMessage = error.message === '混淆结果无效' 
        ? '混淆失败：服务器返回的结果无效' 
        : `混淆失败：${error.message || '请稍后重试'}`;
      alert(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 py-6">
      {/* 预设选择器 */}
      <div className="mb-6">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg font-semibold">混淆强度预设</h2>
          <button
            onClick={() => setShowAdvanced(!showAdvanced)}
            className="flex items-center gap-2 text-indigo-600 hover:text-indigo-500"
          >
            <Settings size={18} />
            {showAdvanced ? '隐藏高级选项' : '显示高级选项'}
          </button>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          {Object.entries(obfuscatorPresets).map(([key, preset]) => (
            <button
              key={key}
              onClick={() => handlePresetChange(key)}
              className={`p-4 rounded-lg border ${
                selectedPreset === key
                  ? 'border-indigo-500 bg-indigo-50'
                  : 'border-gray-200 hover:border-indigo-200'
              }`}
            >
              <h3 className="font-medium">{preset.name}</h3>
              <p className="text-sm text-gray-500 mt-1">{preset.description}</p>
            </button>
          ))}
        </div>
      </div>

      {/* 高级选项 */}
      {showAdvanced && (
        <div className="mb-6 bg-white p-6 rounded-lg shadow">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-semibold">高级配置选项</h2>
            <button
              onClick={resetOptions}
              className="text-sm text-indigo-600 hover:text-indigo-500"
            >
              重置为默认值
            </button>
          </div>
          <div className="space-y-4">
            {Object.entries(ObfuscationOptions).map(([key, group]) => (
              <OptionGroup
                key={key}
                title={group.title}
                options={group.options}
                values={customOptions}
                onChange={handleOptionChange}
                expandedGroup={expandedGroup}
                setExpandedGroup={setExpandedGroup}
              />
            ))}
          </div>
        </div>
      )}

      {/* 代码编辑区 */}
      <div className="grid md:grid-cols-2 gap-6">
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">输入代码：</label>
          <textarea
            value={inputCode}
            onChange={(e) => setInputCode(e.target.value)}
            className="w-full h-96 p-4 font-mono text-sm rounded-lg border focus:ring-2 focus:ring-indigo-500"
            placeholder="在此粘贴您的JavaScript代码..."
          />
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">输出代码：</label>
          <textarea
            value={outputCode}
            readOnly
            className="w-full h-96 p-4 font-mono text-sm rounded-lg border bg-gray-50"
            placeholder="混淆后的代码将显示在这里..."
          />
        </div>
      </div>

      {/* 操作按钮 */}
      <div className="flex justify-center gap-4 mt-6">
        <button
          onClick={handleObfuscate}
          disabled={isLoading || !inputCode}
          className="flex items-center gap-2 px-6 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50"
        >
          {isLoading ? (
            <>
              <RefreshCw className="animate-spin" size={18} />
              处理中...
            </>
          ) : (
            <>
              <Code size={18} />
              开始混淆
            </>
          )}
        </button>

        <button
          onClick={async () => {
            try {
              await navigator.clipboard.writeText(outputCode);
              alert('代码已复制到剪贴板！');
            } catch (error) {
              console.error('复制失败:', error);
              alert('复制失败，请手动复制！');
            }
          }}
          disabled={!outputCode}
          className="flex items-center gap-2 px-6 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700 disabled:opacity-50"
        >
          <Copy size={18} />
          复制代码
        </button>

        <button
          onClick={() => {
            const blob = new Blob([outputCode], { type: 'text/javascript' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'obfuscated.js';
            a.click();
            URL.revokeObjectURL(url);
          }}
          disabled={!outputCode}
          className="flex items-center gap-2 px-6 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 disabled:opacity-50"
        >
          <Download size={18} />
          下载文件
        </button>
      </div>
    </div>
  );
};

export default ObfuscatorUI;