// src/components/TTSGenerator.js
import { useState, useEffect, useRef } from 'react';
import { Loader2 } from 'lucide-react';
import ttsService from '../services/ttsService';

const TTSGenerator = () => {
    const [text, setText] = useState('');
    const [isAudioLoaded, setIsAudioLoaded] = useState(false);
    const [voices, setVoices] = useState([]);
    const [selectedVoice, setSelectedVoice] = useState('zh-CN-XiaoxiaoNeural');
    const [rate, setRate] = useState(0);
    const [volume, setVolume] = useState(0);
    const [format, setFormat] = useState('mp3');
    const [isLoading, setIsLoading] = useState(false);
    const [audioUrl, setAudioUrl] = useState(null);
    const [error, setError] = useState('');
    const [audioBlob, setAudioBlob] = useState(null);
    const [audioDuration, setAudioDuration] = useState(0);
    const audioRef = useRef(null);

    const formatValue = (value) => {
        return (value >= 0 ? '+' : '') + value + '%';
    };

    // 处理音频元数据加载
    const handleAudioMetadata = () => {
        if (audioRef.current) {
            setAudioDuration(audioRef.current.duration);
            setIsAudioLoaded(true);
        }
    };

    useEffect(() => {
        const loadVoices = async () => {
            try {
                const voicesList = await ttsService.getVoices();
                setVoices(voicesList);
            } catch (error) {
                setError('加载语音列表失败');
                console.error('加载语音列表失败:', error);
            }
        };

        loadVoices();
    }, []);

    const createAudioBlob = async (url) => {
        try {
            const blob = await ttsService.downloadAudio(url);
            const blobUrl = URL.createObjectURL(blob);
            setAudioBlob(blobUrl);
            return blobUrl;
        } catch (error) {
            setError('加载音频失败: ' + error.message);
            console.error('音频加载错误:', error);
            return null;
        }
    };

    useEffect(() => {
        return () => {
            if (audioBlob) {
                URL.revokeObjectURL(audioBlob);
            }
        };
    }, [audioBlob]);

    const handleGenerate = async () => {
        if (!text.trim()) {
            setError('请输入要转换的文本');
            return;
        }

        setIsLoading(true);
        setError('');
        
        try {
            if (audioBlob) {
                URL.revokeObjectURL(audioBlob);
                setAudioBlob(null);
            }

            // 生成语音文件
            const url = await ttsService.generateSpeech(text, selectedVoice, {
                rate: formatValue(rate),
                volume: formatValue(volume),
                format
            });

            setAudioUrl(url);
            const blobUrl = await createAudioBlob(url);
            
            if (blobUrl) {
                // 预加载音频
                const audio = new Audio();
                audio.src = blobUrl;
                audio.addEventListener('loadedmetadata', () => {
                    setAudioDuration(audio.duration);
                    setIsAudioLoaded(true);
                });
            }
        } catch (error) {
            setError(error.message || '生成语音失败');
            console.error('生成语音失败:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDownload = async (e) => {
        e.preventDefault();
        if (!audioUrl) return;

        try {
            const blob = await ttsService.downloadAudio(audioUrl);
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `speech.${format}`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            setError('下载文件失败: ' + error.message);
        }
    };

    return (
        <div className="max-w-2xl mx-auto bg-white p-6 rounded-lg shadow-lg">
            <div className="space-y-4">
                {error && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
                        {error}
                    </div>
                )}

                <div>
                    <label className="block text-sm font-medium text-gray-700">
                        文本内容
                    </label>
                    <textarea
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        rows="4"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        placeholder="请输入要转换的文本..."
                    />
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700">
                        语音选择
                    </label>
                    <select
                        value={selectedVoice}
                        onChange={(e) => setSelectedVoice(e.target.value)}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    >
                        {voices.map((voice) => (
                            <option key={voice.name} value={voice.name}>
                                {voice.display_name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700">
                            语速调节
                        </label>
                        <input
                            type="range"
                            min="-50"
                            max="50"
                            value={rate}
                            onChange={(e) => setRate(Number(e.target.value))}
                            className="mt-1 block w-full"
                        />
                        <span className="text-sm text-gray-500">
                            {formatValue(rate)}
                        </span>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700">
                            音量调节
                        </label>
                        <input
                            type="range"
                            min="-50"
                            max="50"
                            value={volume}
                            onChange={(e) => setVolume(Number(e.target.value))}
                            className="mt-1 block w-full"
                        />
                        <span className="text-sm text-gray-500">
                            {formatValue(volume)}
                        </span>
                    </div>
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700">
                        输出格式
                    </label>
                    <select
                        value={format}
                        onChange={(e) => setFormat(e.target.value)}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    >
                        <option value="mp3">MP3</option>
                        <option value="wav">WAV</option>
                    </select>
                </div>

                <button
                    onClick={handleGenerate}
                    disabled={isLoading}
                    className="w-full bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
                >
                    {isLoading ? (
                        <>
                            <Loader2 className="animate-spin mr-2" size={18} />
                            生成中...
                        </>
                    ) : (
                        '生成语音'
                    )}
                </button>

                {audioBlob && (
                    <div className="mt-4">
                        <div className="w-full bg-gray-50 p-4 rounded-lg shadow">
                            <audio 
                                ref={audioRef}
                                controls 
                                className="w-full"
                                controlsList="nodownload noplaybackrate"
                                onLoadedMetadata={handleAudioMetadata}
                                key={audioBlob}
                                preload="metadata"
                            >
                                <source 
                                    src={audioBlob}
                                    type={`audio/${format}`}
                                />
                                您的浏览器不支持音频播放
                            </audio>
                            <div className="mt-3 flex justify-between items-center border-t pt-3">
                                <button
                                    onClick={handleDownload}
                                    className="inline-flex items-center px-4 py-2 border border-indigo-600 rounded-md text-indigo-600 bg-white hover:bg-indigo-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    <span>下载音频</span>
                                </button>
                                <div className="text-sm text-gray-600">
                                    {audioDuration > 0 && (
                                        <span className="font-medium">
                                            时长: {audioDuration.toFixed(1)}秒
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TTSGenerator;
