import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { Loader2 } from 'lucide-react';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { login } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setIsLoading(true);

        try {
            const response = await fetch('https://basechain-service.akakaka.top/api/v1/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
		credentials: 'include',  // 添加这行以支持跨域认证
            });

            const data = await response.json();

            if (data.success) {
                login(data.token, data.user);
                const from = location.state?.from?.pathname || '/obfuscator';
                navigate(from, { replace: true });
            } else {
                setError(data.error || '登录失败，请检查邮箱和密码');
            }
        } catch (err) {
            setError('网络错误，请检查您的连接');
            console.error('Login error:', err);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-gray-900 flex items-center justify-center px-4">
            <div className="max-w-md w-full bg-gray-800 p-8 rounded-lg shadow-lg">
                <h2 className="text-3xl font-bold text-white text-center mb-2">
                    JS 混淆器
                </h2>
                <p className="text-gray-400 text-center mb-8">
                    请登录以继续使用
                </p>
                
                {error && (
                    <div className="bg-red-500/10 border border-red-500/50 text-red-400 p-3 rounded mb-6 text-sm">
                        {error}
                    </div>
                )}

                <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                        <label className="block text-sm font-medium text-gray-300 mb-1">
                            邮箱地址
                        </label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500/50 focus:ring-opacity-50"
                            required
                            disabled={isLoading}
                            placeholder="your@email.com"
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-300 mb-1">
                            密码
                        </label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500/50 focus:ring-opacity-50"
                            required
                            disabled={isLoading}
                            placeholder="••••••••"
                        />
                    </div>

                    <button
                        type="submit"
                        disabled={isLoading}
                        className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2"
                    >
                        {isLoading ? (
                            <>
                                <Loader2 className="animate-spin" size={18} />
                                登录中...
                            </>
                        ) : (
                            '登录'
                        )}
                    </button>

                    {/* 可选：添加注册链接 */}
                    <div className="text-center">
                        <p className="text-sm text-gray-400">
                            还没有账号？
                            <button
                                type="button"
                                onClick={() => navigate('/register')}
                                className="text-blue-400 hover:text-blue-300 focus:outline-none ml-1"
                            >
                                立即注册
                            </button>
                        </p>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default LoginPage;
